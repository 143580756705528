import { useEffect, useState } from "react";

const useIP = () => {
  const [ipData, setIpData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [countryCode, setCountryCode] = useState("ae");
  const [city, setCity] = useState("")
  const [region, setRegion] = useState("")
  const [countryLabel, setCountryFullName] = useState("");

  useEffect(() => {
    const storedIpData = localStorage.getItem("ipData");
    
    if (storedIpData) {
      const parsedData = JSON.parse(storedIpData);
      if(parsedData?.error){
        fetch("https://ipapi.co/json/?key=jSmCY5KC4QIbWGaDzh2MaOiTgq5bzbLAfuUGLoOAqS5hz8Y1tz")
        .then((res) => res.json())
        .then((data) => {
          setIpData(data);
          setCountryCode(data?.country_code ? data?.country_code?.toLowerCase() : "ae");
          setRegion(data?.region)
          setCity(data?.city)
          setCountryFullName(data?.country_name)
          if (data?.ip && data.ip !== ipAddress) {
            setIpAddress(data.ip);
          }
          localStorage.setItem("ipData", JSON.stringify(data));
        })
        .catch((err) => {
        })
      }
      else {
        setIpData(parsedData);
        setIpAddress(parsedData?.ip);
        setCountryCode(parsedData?.country_code ? parsedData?.country_code?.toLowerCase() : "ae");
        setRegion(parsedData?.region);
        setCity(parsedData?.city);
        setCountryFullName(parsedData?.country_name);
      }
     
    } else {
    fetch("https://ipapi.co/json/?key=jSmCY5KC4QIbWGaDzh2MaOiTgq5bzbLAfuUGLoOAqS5hz8Y1tz")
      .then((res) => res.json())
      .then((data) => {
        setIpData(data);
        setCountryCode(data?.country_code ? data?.country_code?.toLowerCase() : "ae");
        setRegion(data?.region)
        setCity(data?.city)
        setCountryFullName(data?.country_name)
        if (data?.ip && data.ip !== ipAddress) {
          setIpAddress(data.ip);
        }
        if(data?.error){

        }
        else{
        localStorage.setItem("ipData", JSON.stringify(data));
        }
      })
      .catch((err) => {
      })
    }
  }, []); // Run only once on mount

  return {
    ...ipData,
    ipAddress,
    countryCode,
    city,
    region,
    countryLabel,
  };
};

export default useIP;