import useInitUTM from "@hooks/useInitUTM"
import React, { useEffect, useRef } from "react"
import { AuthStoreProvider } from "@starberry/myaccount-website-utils"
import { hasMyaccount } from "@lib/utils"
const App = ({ children }) => {
  useInitUTM()
  const initGTM = useRef(null)
  const initGTMOnEvent = useRef(null)

  useEffect(() => {
    initGTM.current = () => {
      if (window.gtmDidInit) {
        return
      }
      window.gtmDidInit = true

      const script = document.createElement("script")
      script.type = "text/javascript"
      script.async = true
      script.onload = () => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "gtm.js",
          "gtm.start": new Date().getTime(),
          "gtm.uniqueEventId": 0,
        })
      }
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-PGNHTGZ5"
      document.head.appendChild(script)
    }

    initGTMOnEvent.current = (event) => {
      initGTM.current()
      event.currentTarget.removeEventListener(
        event.type,
        initGTMOnEvent.current
      )
    }

    // const initGTMAfterDelay = setTimeout(initGTM.current, 3500)

    const handleScroll = initGTMOnEvent.current
    const handleMouseMove = initGTMOnEvent.current
    const handleTouchStart = initGTMOnEvent.current

    document.addEventListener("scroll", handleScroll)
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("touchstart", handleTouchStart)

    return () => {
      // clearTimeout(initGTMAfterDelay)
      document.removeEventListener("scroll", handleScroll)
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("touchstart", handleTouchStart)
    }
  }, [])

  // const initLandbot = useRef(null)
  // const myLandbot = useRef(null)

  // initLandbot.current = () => {
  //   if (!myLandbot.current) {
  //     const script = document.createElement("script")
  //     script.type = "text/javascript"
  //     script.async = true
  //     script.addEventListener("load", () => {
  //       myLandbot.current = new Landbot.Livechat({
  //         configUrl:
  //           "https://storage.googleapis.com/chats.landbot.io/v3/H-1167807-EII5RTVGT6GG4053/index.json",
  //       })
  //     })
  //     script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js"
  //     const firstScript = document.getElementsByTagName("script")[0]
  //     firstScript.parentNode.insertBefore(script, firstScript)
  //   }
  // }

  // const initHotjar = useRef(null)

  // initHotjar.current = function (h, o, t, j, a, r) {
  //   h.hj =
  //     h.hj ||
  //     function () {
  //       ;(h.hj.q = h.hj.q || []).push(arguments)
  //     }
  //   h._hjSettings = { hjid: 3636772, hjsv: 6 }
  //   a = o.getElementsByTagName("head")[0]
  //   r = o.createElement("script")
  //   r.async = 1
  //   r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  //   a.appendChild(r)
  // }

  // useEffect(() => {
  //   const handleInitLandbot = () => {
  //     // initLandbot.current()
  //     initHotjar.current(
  //       window,
  //       document,
  //       "https://static.hotjar.com/c/hotjar-",
  //       ".js?sv="
  //     )
  //     window.removeEventListener("mouseover", handleInitLandbot)
  //     window.removeEventListener("touchstart", handleInitLandbot)
  //   }

  //   window.addEventListener("mouseover", handleInitLandbot, { once: true })
  //   window.addEventListener("touchstart", handleInitLandbot, { once: true })

  //   return () => {
  //     window.removeEventListener("mouseover", handleInitLandbot)
  //     window.removeEventListener("touchstart", handleInitLandbot)
  //   }
  // }, [])
  return <>{children}</>
}

export const wrapRootElement = ({ element }) => {
  return <App><AuthStoreProvider><div className={hasMyaccount ? 'has-myacc' : 'no-myacc'}>{element}</div></AuthStoreProvider></App>
}
