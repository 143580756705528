const buyURL = "/buy/"
const sellURL = "/sell/"
const rentURL = "/rent/"
const offPlanURL = "/off-plan/"
const servicesURL = "/property-services/"
const aboutURL = "/about/"
const propertiesURL = "/properties/"
const offplanResultsURL = "/new-projects/"
const propertiesSaleURL = "/buy/properties-for-sale/"
const propertiesRentURL = "/let/properties-for-rent/"
const commercialPropertiesSaleURL = "/buy/commercial-properties-for-sale/"
const commercialPropertiesRentURL = "/let/commercial-properties-for-rent/"

const getPropertyURL = (pathname) => {
  if (pathname?.includes("-for-sale"))
    return pathname.match(/\/buy\/[^/]+-for-sale\//)?.[0]

  if (pathname?.includes("-for-rent"))
    return pathname.match(/\/let\/[^/]+-for-rent\//)?.[0]

  return null
}

const generatePropertyURL = ({ searchType, buildingType }) => {
  if (searchType?.includes("sale") || searchType?.includes("buy")) {
    if (buildingType === "" || !buildingType) return propertiesSaleURL
    return `/buy/${buildingType}-for-sale/`
  }

  if (buildingType === "" || !buildingType) return propertiesRentURL
  return `/let/${buildingType}-for-rent/`
}

module.exports = {
  propertySaleRegex: /\/buy\/[^/]+-for-sale\//,
  propertySaleRegex2: /\/buy\/(?:\d{1,},?)+-bedroom-(\w+)\//,
  propertyRentRegex: /\/let\/[^/]+-for-rent\//,
  propertyRentRegex2: /\/let\/(?:\d{1,},?)+-bedroom-(\w+)\//,
  // propertySaleRegex: /\/buy\/[^/]+-for-sale\/[^/]+\//,
  // propertyRentRegex: /\/let\/[^/]+-for-rent\/[^/]+\//,
  getPropertyURL,
  sitemapURL: "/sitemap/",
  privacyPolicyURL: "/privacy-policy/",
  termsAndConditionsURL: "/terms-and-conditions/",
  cookiePolicyURL: "/cookie-policy/",
  propertiesURL,
  propertiesSaleURL,
  propertiesRentURL,
  commercialPropertiesSaleURL,
  commercialPropertiesRentURL,
  propertyDetailSaleURL: "/buy/",
  propertyDetailRentURL: "/let/",
  developersURL: `${offplanResultsURL}developed-by-`,
  homePageURL: "/",
  buyURL,
  whyBuyWithUsURL: `${buyURL}why-buy-property-with-us/`,
  mortgageCalculatorURL: `${buyURL}mortgage-calculator/`,
  guidesBuyURL: `${buyURL}property-buying-guide/`,
  sellURL,
  sellYourPropertyURL: `${sellURL}sell-your-property/`,
  whySellWithUsURL: `${sellURL}why-sell-property-with-us/`,
  guidesSellURL: `${sellURL}property-selling-guide/`,
  rentURL,
  whyRentWithUsURL: `${rentURL}why-rent-property-with-us/`,
  rentalYieldCalculatorURL: `${rentURL}rental-yield-calculator/`,
  guidesRentURL: `${rentURL}property-renting-guide/`,
  offPlanURL,
  offplanResultsURL,
  offplanResultsSaleURL: `${offplanResultsURL}`,
  offplanDetailURL: "/off-plan-property/",
  whyOffPlanWithUsURL: `${offPlanURL}why-buy-off-plan-property-with-us/`,
  mortgageAndRentalYieldCalculatorsURL: `${offPlanURL}mortgage-and-rental-yield-calculators/`,
  guidesOffPlanURL: `${offPlanURL}property-guide/`,
  servicesURL,
  leasingURL: `${servicesURL}leasing/`,
  mortgagesURL: `${servicesURL}mortgages/`,
  holidayHomesURL: `${servicesURL}holiday-homes/`,
  conveyancingURL: `${servicesURL}conveyancing/`,
  propertySnaggingURL: `${servicesURL}property-snagging/`,
  secondCitizenshipURL: `${servicesURL}second-citizenship/`,
  forexServicesURL: `${servicesURL}forex-services/`,
  partialOwnershipURL: `${servicesURL}partial-ownership/`,
  aboutURL,
  ourAwardsURL: `${aboutURL}our-awards/`,
  teamsURL: "/team/",
  reviewsURL: `${aboutURL}reviews/`,
  philanthropyURL: `${aboutURL}philanthropy/`,
  careersURL: "/careers/",
  newsAndInsightURL: "/blog/",
  sendCVURL: "/send-us-your-cv/",
  communitiesURL: "/area-guides/",
  bookAViewingURL: "/book-a-viewing/",
  listYourPropertyURL: "/list-your-property/",
  contactURL: "/contact/",
  generatePropertyURL,
}
