import { some, isEmpty, reject } from "lodash"
const GATSBY_GGFX_CDN_ENABLED = process.env.GATSBY_GGFX_CDN_ENABLED
const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i"
const GATSBY_GGFX_CDN_HOST = process.env.GATSBY_GGFX_CDN_HOST
const GATSBY_GGFX_S3_HOST = process.env.GATSBY_GGFX_S3_HOST

export const isBrowser = () => {
  return typeof window !== "undefined"
}

export const hasEmptyPropsInObj = objParam => {
  return some(objParam, isEmpty)
}

export const removeEmptyPropsInObjs = arrayParam => {
  return reject(arrayParam, isEmpty)
}

export const parseOriginalImg = img => {
  return img ? img.replace(/File /, "").replace(/["]/g, "") : ""
}

export const FormCdnUrl = (url = "") =>
  ((GATSBY_GGFX_CDN_ENABLED === "true") && url) ? url.replace(GATSBY_GGFX_S3_HOST,GATSBY_GGFX_CDN_HOST) : url