/* eslint-disable max-lines */
import { offplanResultsURL, propertiesRentURL, propertiesSaleURL } from "../../../lib/urls"

export const INDEX_NAME = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
export const OFFPLAN_INDEX_NAME = `${process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENTS_INDEX_NAME}`

export const sortOptions = [
  { value: INDEX_NAME, label: "Most Recent" },
  {
    value: `${INDEX_NAME}_price_desc`,
    label: "Highest Price",
  },
  {
    value: `${INDEX_NAME}_price_asc`,
    label: "Lowest Price",
  },
]

export const seoConData = [
  { id: 4863, name: "Ad China" },
  { id: 32887, name: "Adroll" },
  { id: 32972, name: "Agent Registration" },
  { id: 3359, name: "Bayut" },
  { id: 3420, name: "Call Gear" },
  { id: 3357, name: "Direct call" },
  { id: 3356, name: "Dubizzle" },
  { id: 3397, name: "Email" },
  { id: 3360, name: "Emailer cc" },
  { id: 3361, name: "Emailer liana" },
  { id: 3402, name: "Emailer Madmimi" },
  { id: 3362, name: "Emailer mailmodo" },
  { id: 3363, name: "Emailer mc" },
  { id: 3364, name: "Emailer mm" },
  { id: 3365, name: "Emailer sb" },
  { id: 3372, name: "Google Discovery" },
  { id: 3370, name: "Google Display" },
  { id: 24228, name: "Google Lead Form" },
  { id: 3371, name: "Google Pmax" },
  { id: 3369, name: "Google Search" },
  { id: 3355, name: "Hootsuite" },
  { id: 32960, name: "Hotel" },
  { id: 32907, name: "Influencer" },
  { id: 3373, name: "Landbot" },
  { id: 3434, name: "Lead Farming" },
  { id: 3374, name: "Marquiz" },
  { id: 3368, name: "Meta Conv" },
  { id: 3377, name: "Meta Engagement" },
  { id: 3433, name: "Meta Lead Gen" },
  { id: 3375, name: "Meta Reach" },
  { id: 3376, name: "Meta Traffic" },
  { id: 3398, name: "New Line Marketing" },
  { id: 3379, name: "Offplan Website" },
  { id: 30628, name: "Organic" },
  { id: 3396, name: "Others" },
  { id: 3380, name: "Pop Up" },
  { id: 3358, name: "Property Finder" },
  { id: 3399, name: "Property Finder" },
  { id: 3400, name: "Propsearch" },
  { id: 3378, name: "Provident Website" },
  { id: 32929, name: "Push Notification" },
  { id: 30800, name: "Reddit Ads" },
  { id: 19541, name: "Send Pulse" },
  { id: 32836, name: "SMS Campaign" },
  { id: 3381, name: "Snapchat" },
  { id: 3384, name: "Telegram" },
  { id: 3382, name: "Tiktok" },
  { id: 3383, name: "Twitter" },
  { id: 32871, name: "Walkin Client" },
  { id: 3386, name: "WhatsApp" },
  { id: 3385, name: "WhatsApp Verloop" },
  { id: 3401, name: "Wordpress" },
  { id: 3387, name: "Yandex" },
  { id: 16464, name: "Yandex SM" },
  { id: 24221, name: "Youtube Ads" },
  { id: 201834, name: "Whatsapp Blast" },
  { id: 241747, name: "Pinterest" },
  { id: 252632, name: "Public Relations" },
  { id: 252962, name: "Demand Gen" },
  { id: 253070, name: "James Edition" },
  { id: 253084, name: "Email Journey" },
  { id: 254341, name: "Tr Ad" },
  { id: 254767, name: "VideoASK" },
  { id: 256371,	name: "Mailer GetResponse" },
  { id: 261303,	name: "Linkedin Ads" },
  { id: 256288,	name: "GetResponse Forms" },
];


export const currencyTypeOptions = [
  // { label: "Currency", value: "" },
  { value: "AED", label: "AED" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "USD", label: "USD" },
]

export const currencyTypeOptionsSymbols = [
  // { label: "Currency", value: "" },
  { value: "AED", label: "UAE Dirams - AED د.إ" },
  { value: "EUR", label: "Euros - EUR €" },
  { value: "GBP", label: "Pound Sterling - GBP £" },
  { value: "USD", label: "US Dollars - USD $" },
]

export const currencyTypeOptionsBudget = [
  // { label: "Currency", value: "" },
  { value: "AED", label: "AED" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "USD", label: "USD" },
]

export const languageOptions = [
  // { label: "language", value: "" },
  { value: "EN", label: "English" },
  { value: "RUS", label: "Russia" },
]
export const offplanSortOptions = [
  { value: OFFPLAN_INDEX_NAME, label: "Most Recent" },
  {
    value: `${OFFPLAN_INDEX_NAME}_price_desc`,
    label: "Highest Price",
  },
  {
    value: `${OFFPLAN_INDEX_NAME}_price_asc`,
    label: "Lowest Price",
  },
]

export const offplanStatusOptions = [
  { value: "", label: "All Status" },
  { value: "ready", label: "Ready" },
  { value: "pending", label: "Pending" },
]

// Ptypes
export const propertySaleRentOptions = [
  { value: "for-sale", label: "Buy" },
  { value: "for-rent", label: "Rent" },
]
export const propertySaleRentUrlOptions = [
  { value: propertiesSaleURL, label: "Buy" },
  { value: propertiesRentURL, label: "Rent" },
  { value: offplanResultsURL, label: "Off Plan" },
]

export const locationOptions = [
  { label: "Location", value: "" },
  { label: "Canterbury", value: "canterbury" },
  { label: "Faversham", value: "faversham" },
  { label: "Herne Bay", value: "herne-bay" },
  { label: "Sittingbourne", value: "sittingbourne" },
  { label: "Whitstable", value: "whitstable" },
]

// Property type
export const resiPropertyTypeOptions = [
  { label: "Property Type", value: "" },
  { label: "Apartment", value: "apartment" },
  { label: "Villa", value: "villa" },
  { label: "Townhouse", value: "townhouse" },
  { label: "Penthouse", value: "penthouse" },
  { label: "Short Term", value: "short-term" },
  { label: "Duplex", value: "duplex" },
  { label: "Whole Builing", value: "whole-building" },
  { label: "Plot", value: "land" },
  { label: "Office Space", value: "office-space" },
  { label: "Shop", value: "shop" },
  { label: "Retail", value: "retail" },
]

// Property type
export const commercialPropertyTypeOptions = [
  { label: "Property Type", value: "" },
  { label: "Retail", value: "retail" },
  { label: "Office Space", value: "office-space" },
  { label: "Shop", value: "shop" },
  { label: "Show Room", value: "show-room" },
  { label: "Warehouse", value: "warehouse" },
  { label: "Factory", value: "factory" },
]

// Property type
export const Furnishing = [
  { label: "Select Furnishing", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Partly", value: "partly" },
]

export const VirtualViewing = [
  { label: "Select Viewing", value: "" },
  { label: "360 Tours", value: "360-tour" },
  // {label: "3D Tour", value: "3d-tour"},
  { label: "Video Tours", value: "video-tour" },
]
// Price Min Residential Sales
export const minPriceOptionsResiSale = [
  {
    value: "",
    label: "No Min",
  },
  {
    value: "300000",
    label: "AED 300,000",
  },
  {
    value: "400000",
    label: "AED 400,000",
  },
  {
    value: "500000",
    label: "AED 500,000",
  },
  {
    value: "600000",
    label: "AED 600,000",
  },
  {
    value: "700000",
    label: "AED 700,000",
  },
  {
    value: "800000",
    label: "AED 800,000",
  },
  {
    value: "900000",
    label: "AED 900,000",
  },
  {
    value: "1000000",
    label: "AED 1,000,000",
  },
  {
    value: "1100000",
    label: "AED 1,100,000",
  },
  {
    value: "1200000",
    label: "AED 1,200,000",
  },
  {
    value: "1300000",
    label: "AED 1,300,000",
  },
  {
    value: "1400000",
    label: "AED 1,400,000",
  },
  {
    value: "1500000",
    label: "AED 1,500,000",
  },
  {
    value: "1600000",
    label: "AED 1,600,000",
  },
  {
    value: "1700000",
    label: "AED 1,700,000",
  },
  {
    value: "1800000",
    label: "AED 1,800,000",
  },
  {
    value: "1900000",
    label: "AED 1,900,000",
  },
  {
    value: "2000000",
    label: "AED 2,000,000",
  },
  {
    value: "2100000",
    label: "AED 2,100,000",
  },
  {
    value: "2200000",
    label: "AED 2,200,000",
  },
  {
    value: "2300000",
    label: "AED 2,300,000",
  },
  {
    value: "2400000",
    label: "AED 2,400,000",
  },
  {
    value: "2500000",
    label: "AED 2,500,000",
  },
  {
    value: "2600000",
    label: "AED 2,600,000",
  },
  {
    value: "2700000",
    label: "AED 2,700,000",
  },
  {
    value: "2800000",
    label: "AED 2,800,000",
  },
  {
    value: "2900000",
    label: "AED 2,900,000",
  },
  {
    value: "3000000",
    label: "AED 3,000,000",
  },
  {
    value: "3250000",
    label: "AED 3,250,000",
  },
  {
    value: "3500000",
    label: "AED 3,500,000",
  },
  {
    value: "3750000",
    label: "AED 3,750,000",
  },
  {
    value: "4000000",
    label: "AED 4,000,000",
  },
  {
    value: "4250000",
    label: "AED 4,250,000",
  },
  {
    value: "4500000",
    label: "AED 4,500,000",
  },
  {
    value: "5000000",
    label: "AED 5,000,000",
  },
  {
    value: "6000000",
    label: "AED 6,000,000",
  },
  {
    value: "7000000",
    label: "AED 7,000,000",
  },
  {
    value: "8000000",
    label: "AED 8,000,000",
  },
  {
    value: "9000000",
    label: "AED 9,000,000",
  },
  {
    value: "10000000",
    label: "AED 10,000,000",
  },
  {
    value: "20000000",
    label: "AED 20,000,000",
  },
  {
    value: "25000000",
    label: "AED 25,000,000",
  },
  {
    value: "50000000",
    label: "AED 50,000,000",
  },
]
// Price Min Residential Sales

// Price Max Residential Sales
export const maxPriceOptionsResiSale = [
  {
    value: "",
    label: "No Max",
  },
  {
    value: "300000",
    label: "AED 300,000",
  },
  {
    value: "400000",
    label: "AED 400,000",
  },
  {
    value: "500000",
    label: "AED 500,000",
  },
  {
    value: "600000",
    label: "AED 600,000",
  },
  {
    value: "700000",
    label: "AED 700,000",
  },
  {
    value: "800000",
    label: "AED 800,000",
  },
  {
    value: "900000",
    label: "AED 900,000",
  },
  {
    value: "1000000",
    label: "AED 1,000,000",
  },
  {
    value: "1100000",
    label: "AED 1,100,000",
  },
  {
    value: "1200000",
    label: "AED 1,200,000",
  },
  {
    value: "1300000",
    label: "AED 1,300,000",
  },
  {
    value: "1400000",
    label: "AED 1,400,000",
  },
  {
    value: "1500000",
    label: "AED 1,500,000",
  },
  {
    value: "1600000",
    label: "AED 1,600,000",
  },
  {
    value: "1700000",
    label: "AED 1,700,000",
  },
  {
    value: "1800000",
    label: "AED 1,800,000",
  },
  {
    value: "1900000",
    label: "AED 1,900,000",
  },
  {
    value: "2000000",
    label: "AED 2,000,000",
  },
  {
    value: "2100000",
    label: "AED 2,100,000",
  },
  {
    value: "2200000",
    label: "AED 2,200,000",
  },
  {
    value: "2300000",
    label: "AED 2,300,000",
  },
  {
    value: "2400000",
    label: "AED 2,400,000",
  },
  {
    value: "2500000",
    label: "AED 2,500,000",
  },
  {
    value: "2600000",
    label: "AED 2,600,000",
  },
  {
    value: "2700000",
    label: "AED 2,700,000",
  },
  {
    value: "2800000",
    label: "AED 2,800,000",
  },
  {
    value: "2900000",
    label: "AED 2,900,000",
  },
  {
    value: "3000000",
    label: "AED 3,000,000",
  },
  {
    value: "3250000",
    label: "AED 3,250,000",
  },
  {
    value: "3500000",
    label: "AED 3,500,000",
  },
  {
    value: "3750000",
    label: "AED 3,750,000",
  },
  {
    value: "4000000",
    label: "AED 4,000,000",
  },
  {
    value: "4250000",
    label: "AED 4,250,000",
  },
  {
    value: "4500000",
    label: "AED 4,500,000",
  },
  {
    value: "5000000",
    label: "AED 5,000,000",
  },
  {
    value: "6000000",
    label: "AED 6,000,000",
  },
  {
    value: "7000000",
    label: "AED 7,000,000",
  },
  {
    value: "8000000",
    label: "AED 8,000,000",
  },
  {
    value: "9000000",
    label: "AED 9,000,000",
  },
  {
    value: "10000000",
    label: "AED 10,000,000",
  },
  {
    value: "20000000",
    label: "AED 20,000,000",
  },
  {
    value: "25000000",
    label: "AED 25,000,000",
  },
  {
    value: "50000000",
    label: "AED 50,000,000",
  },
]
// Price Max Residential Sales

// Price Min Residential Lettings
export const minPriceOptionsResiRent = [
  {
    value: "",
    label: "No Min",
  },
  {
    value: "500",
    label: "AED 500",
  },
  {
    value: "1000",
    label: "AED 1,000",
  },
  {
    value: "2000",
    label: "AED 2,000",
  },
  {
    value: "3000",
    label: "AED 3,000",
  },
  {
    value: "4000",
    label: "AED 4,000",
  },
  {
    value: "5000",
    label: "AED 5,000",
  },
  {
    value: "10000",
    label: "AED 10,000",
  },
  {
    value: "20000",
    label: "AED 20,000",
  },
  {
    value: "30000",
    label: "AED 30,000",
  },
  {
    value: "40000",
    label: "AED 40,000",
  },
  {
    value: "50000",
    label: "AED 50,000",
  },
  {
    value: "60000",
    label: "AED 60,000",
  },
  {
    value: "70000",
    label: "AED 70,000",
  },
  {
    value: "80000",
    label: "AED 80,000",
  },
  {
    value: "90000",
    label: "AED 90,000",
  },
  {
    value: "100000",
    label: "AED 100,000",
  },
  {
    value: "110000",
    label: "AED 110,000",
  },
  {
    value: "120000",
    label: "AED 120,000",
  },
  {
    value: "130000",
    label: "AED 130,000",
  },
  {
    value: "140000",
    label: "AED 140,000",
  },
  {
    value: "150000",
    label: "AED 150,000",
  },
  {
    value: "160000",
    label: "AED 160,000",
  },
  {
    value: "170000",
    label: "AED 170,000",
  },
  {
    value: "180000",
    label: "AED 180,000",
  },
  {
    value: "190000",
    label: "AED 190,000",
  },
  {
    value: "200000",
    label: "AED 200,000",
  },
  {
    value: "225000",
    label: "AED 225,000",
  },
  {
    value: "250000",
    label: "AED 250,000",
  },
  {
    value: "275000",
    label: "AED 275,000",
  },
  {
    value: "300000",
    label: "AED 300,000",
  },
  {
    value: "350000",
    label: "AED 350,000",
  },
  {
    value: "400000",
    label: "AED 400,000",
  },
  {
    value: "500000",
    label: "AED 500,000",
  },
  {
    value: "750000",
    label: "AED 750,000",
  },
  {
    value: "1000000",
    label: "AED 1,000,000",
  },
]
// Price Min Residential Lettings

// Price Max Residential Lettings
export const maxPriceOptionsResiRent = [
  {
    value: "",
    label: "No Max",
  },
  {
    value: "500",
    label: "AED 500",
  },
  {
    value: "1000",
    label: "AED 1,000",
  },
  {
    value: "2000",
    label: "AED 2,000",
  },
  {
    value: "3000",
    label: "AED 3,000",
  },
  {
    value: "4000",
    label: "AED 4,000",
  },
  {
    value: "5000",
    label: "AED 5,000",
  },
  {
    value: "10000",
    label: "AED 10,000",
  },
  {
    value: "20000",
    label: "AED 20,000",
  },
  {
    value: "30000",
    label: "AED 30,000",
  },
  {
    value: "40000",
    label: "AED 40,000",
  },
  {
    value: "50000",
    label: "AED 50,000",
  },
  {
    value: "60000",
    label: "AED 60,000",
  },
  {
    value: "70000",
    label: "AED 70,000",
  },
  {
    value: "80000",
    label: "AED 80,000",
  },
  {
    value: "90000",
    label: "AED 90,000",
  },
  {
    value: "100000",
    label: "AED 100,000",
  },
  {
    value: "110000",
    label: "AED 110,000",
  },
  {
    value: "120000",
    label: "AED 120,000",
  },
  {
    value: "130000",
    label: "AED 130,000",
  },
  {
    value: "140000",
    label: "AED 140,000",
  },
  {
    value: "150000",
    label: "AED 150,000",
  },
  {
    value: "160000",
    label: "AED 160,000",
  },
  {
    value: "170000",
    label: "AED 170,000",
  },
  {
    value: "180000",
    label: "AED 180,000",
  },
  {
    value: "190000",
    label: "AED 190,000",
  },
  {
    value: "200000",
    label: "AED 200,000",
  },
  {
    value: "225000",
    label: "AED 225,000",
  },
  {
    value: "250000",
    label: "AED 250,000",
  },
  {
    value: "275000",
    label: "AED 275,000",
  },
  {
    value: "300000",
    label: "AED 300,000",
  },
  {
    value: "350000",
    label: "AED 350,000",
  },
  {
    value: "400000",
    label: "AED 400,000",
  },
  {
    value: "500000",
    label: "AED 500,000",
  },
  {
    value: "750000",
    label: "AED 750,000",
  },
  {
    value: "1000000",
    label: "AED 1,000,000",
  },
]
// Price Max Residential Lettings

// Bedrooms
export const bedroomOptions = [
  { label: "Bedrooms", value: "" },
  { label: "Studio", value: "0" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
  { label: "6+", value: "6" },
  { label: "7+", value: "7" },
  { label: "8+", value: "8" },
  { label: "9+", value: "9" },
]

export const minBedroomOptions = [
  { label: "No Min", value: "" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
]

export const maxBedroomOptions = [
  { label: "No Max", value: "" },
  { label: "Studio", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
]
// Bedrooms

// Bathrooms
export const bathroomsRange = [
  { label: "Bathrooms", value: "" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
  { label: "6+", value: "6" },
  { label: "7+", value: "7" },
  { label: "8+", value: "8" },
  { label: "9+", value: "9" },
]
// Bathrooms
// Bedrooms
export const ParkingRange = [
  { label: "Select Parking", value: "" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
]
// Bedrooms

// SQFT

export const areaSizeOptions = [
  { label: "500 Sqft", value: "500" },
  { label: "1,000 Sqft", value: "1000" },
  { label: "2,000 Sqft", value: "2000" },
  { label: "3,000 Sqft", value: "3000" },
  { label: "4,000 Sqft", value: "4000" },
  { label: "5,000 Sqft", value: "5000" },
  { label: "6,000 Sqft", value: "6000" },
  { label: "7,000 Sqft", value: "7000" },
  { label: "8,000 Sqft", value: "8000" },
  { label: "9,000 Sqft", value: "9000" },
  { label: "10,000 Sqft", value: "10000" },
  { label: "11,000 Sqft", value: "11000" },
  { label: "12,000 Sqft", value: "12000" },
  { label: "13,000 Sqft", value: "13000" },
  { label: "14,000 Sqft", value: "14000" },
  { label: "15,000 Sqft", value: "15000" },
]
export const minAreaSizeOptions = [
  { label: "No Min", value: "" },
  ...areaSizeOptions,
]
// SQFT
// SQFT
export const maxAreaSizeOptions = [
  { label: "No Max", value: "" },
  ...areaSizeOptions,
]
// SQFT

// Property amenities checkboxes
export const amenitiesCheckboxArray = [
  {
    label: "Central A/C & Heating",
    value: "central-a-c-heating",
    checked: false,
  },
  { label: "Covered Parking", value: "covered-parking", checked: false },
  { label: "Built in Wardrobes", value: "built-in-wardrobes", checked: false },
  { label: "Security", value: "security", checked: false },
  { label: "Shared Gym", value: "shared-gym", checked: false },
  { label: "Balcony", value: "balcony", checked: false },
  { label: "Shared Pool", value: "shared-pool", checked: false },
  {
    label: "Built in Kitchen Appliances",
    value: "built-in-kitchen-appliances",
    checked: false,
  },
  { label: "Concierge Service", value: "concierge-service", checked: false },
  { label: "Shared Spa", value: "shared-spa", checked: false },
  { label: "Pets Allowed", value: "pets-allowed", checked: false },
  { label: "View of Water", value: "view-of-water", checked: false },
  { label: "View of Landmark", value: "view-of-landmark", checked: false },
  { label: "Maids Room", value: "maids-room", checked: false },
  { label: "Walk in Closet", value: "walk-in-closet", checked: false },
  { label: "Private Garden", value: "private-garden", checked: false },
  { label: "Study", value: "study", checked: false },
  { label: "Maid Service", value: "maid-service", checked: false },
  { label: "Private Pool", value: "private-pool", checked: false },
  { label: "Private Jacuzzi", value: "private-jacuzzi", checked: false },
  { label: "Private Gym", value: "private-gym", checked: false },
]

export const developerOptions = [
  { label: "Developer", value: "" },
  { label: "Emaar Properties", value: "Emaar" },
  { label: "Nakheel", value: "Nakheel" },
  { label: "Dubai Properties", value: "Dubai" },
  { label: "Meraas", value: "Meraas" },
  { label: "Meydan", value: "Meydan" },
  { label: "Sobha", value: "Sobha" },
  { label: "Deyaar", value: "Deyaar" },
  { label: "Omniyat", value: "Omniyat" },
  { label: "Damac Properties", value: "Damac" },
  { label: "Mag Property Development", value: "Mag Property Development" },
  { label: "Majid Al Futtaim", value: "Majid Al Futtaim" },
  { label: "Ellington Properties", value: "Ellington" },
  { label: "Binghatti", value: "Binghatti" },
  { label: "Bloom Properties", value: "Bloom" },
  { label: "Tiger Properties", value: "Tiger" },
  { label: "Danube Properties", value: "Danube" },
  { label: "Falcon City of Wonders LLC", value: "Falcon City of Wonders LLC" },
  { label: "Five Holdings", value: "Five Holdings" },
  { label: "Green Group", value: "Green Group" },
  { label: "ICD Brookfield", value: "ICD Brookfield" },
  { label: "Iman Developers", value: "Iman Developers" },
  { label: "Indigo Properties", value: "Indigo" },
  { label: "Ishraqah", value: "Ishraqah" },
  { label: "Jumeirah Golf Estates", value: "Jumeirah Golf Estates" },
  { label: "Jumeirah Group", value: "Jumeirah Group" },
  { label: "Liv Developers", value: "Liv Developers" },
  {
    label: "Lootah Real Estate Development",
    value: "Lootah Real Estate Development",
  },
  { label: "Meraki Developers", value: "Meraki Developers" },
  { label: "Meydan Sobha", value: "Meydan Sobha" },
  { label: "Nshama", value: "Nshama" },
  { label: "Palma Development", value: "Palma Development" },
  { label: "Pantheon Development", value: "Pantheon Development" },
  { label: "Select Group", value: "Select Group" },
  { label: "Sobha Developers", value: "Sobha Developers" },
  { label: "Sweid & Sweid", value: "Sweid & Sweid" },
  { label: "Tebyan", value: "Tebyan" },
  { label: "The First Group", value: "The First Group" },
  { label: "Union Properties", value: "Union" },
  { label: "Victory Heights", value: "Victory Heights" },
  { label: "Wasl Properties", value: "Wasl Properties" },
  { label: "Westar Properties", value: "Westar" },
]

export const completionRange = [
  { label: "Completion", value: "" },
  { label: "Ready", value: "ready" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
]
export const rentalPeriodOptions = [
  { label: "Rental Period", value: "" },
  { label: "Yearly", value: "yearly" },
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
  { label: "Daily", value: "daily" },
]

// Search overlay dropdown
export const searchOverlayOptions = [
  { label: "Buy", value: "buy" },
  { label: "Rent", value: "rent" },
  { label: "Off Plan", value: "offplan" },
  { label: "Holiday Lets", value: "holidaylets" },
]
// Search overlay dropdown

export const amenitiesOptions = [
  { label: "All", value: "all", icon: "all" },
  {
    label: "Swimming pools",
    value: "swimming-pool",
    icon: "swimming-pool",
  },
  {
    label: "Fitness facilities",
    value: "shared-gym",
    icon: "fitness-facilities",
  },
  {
    label: "Security and privacy",
    value: "security",
    icon: "security-and-privacy",
  },
  {
    label: "Outdoor spaces",
    value: "outdoor-spaces",
    icon: "outdoor-spaces",
  },
  {
    label: "Concierge Services",
    value: "concierge-service",
    icon: "concierge",
  },
  {
    label: "Children's play area",
    value: "childrens-play-area",
    icon: "childrens-play-areas",
  },
  {
    label: "Parking facilities",
    value: "covered-parking",
    icon: "parking-facilities",
  },
  {
    label: "Retail & dining",
    value: "retail-dining",
    icon: "retail-dining",
  },
  {
    label: "Spa and wellness facilities",
    value: "spa",
    icon: "spa",
  },
  {
    label: "Pets Friendly",
    value: "pets-allowed",
    icon: "pet-friendly",
  },
  {
    label: "Beachfront properties",
    value: "beachfront-properties",
    icon: "beachfront-properties",
  },
  // {
  //   label: "Big city life",
  //   value: "big-city-life",
  //   icon: "big-city-life",
  // },
  // {
  //   label: "Branded residences",
  //   value: "branded-residences",
  //   icon: "branded-residences",
  // },
  // {
  //   label: "Family community",
  //   value: "family-community",
  //   icon: "family-community",
  // },
  // {
  //   label: "Green nature living",
  //   value: "green-nature-living",
  //   icon: "green-nature-living",
  // },
  {
    label: "Luxury living",
    value: "luxury-living",
    icon: "luxury-living",
  },
  {
    label: "Near golf course",
    value: "near-golf-course",
    icon: "near-golf-course",
  },
  {
    label: "Near Metro",
    value: "near-metro",
    icon: "near-metro",
  },
  // {
  //   label: "Villa Community",
  //   value: "villa-community",
  //   icon: "villa-community",
  // },
  // {
  //   label: "Waterfront properties",
  //   value: "waterfront-properties",
  //   icon: "waterfront-properties",
  // },
]

export const _amenitiesOptions = [
  { label: "All", value: "all" },
  {
    label: "Balcony",
    value: "balcony",
  },
  {
    label: "Basement parking",
    value: "basement-parking",
  },
  {
    label: "BBQ area",
    value: "bbq-area",
  },
  {
    label: "Cable-ready",
    value: "cable-ready",
  },
  {
    label: "Built in wardrobes",
    value: "built-in-wardrobes",
  },
  {
    label: "Carpets",
    value: "carpets",
  },
  {
    label: "Central air conditioning",
    value: "central-air-conditioning",
  },
  {
    label: "Covered parking",
    value: "covered-parking",
  },
  {
    label: "Drivers room",
    value: "drivers-room",
  },
  {
    label: "Fully fitted kitchen",
    value: "fully-fitted-kitchen",
  },
  {
    label: "Gazebo",
    value: "gazebo",
  },
  {
    label: "Private Gym",
    value: "private-gym",
  },
  {
    label: "Jacuzzi",
    value: "jacuzzi",
  },
  {
    label: "Kitchen Appliances",
    value: "kitchen-appliances",
  },
  {
    label: "Maids Room",
    value: "maids-room",
  },
  {
    label: "Marble floors",
    value: "marble-floors",
  },
  {
    label: "On high floor",
    value: "on-high-floor",
  },
  {
    label: "On low floor",
    value: "on-low-floor",
  },
  {
    label: "On mid floor",
    value: "on-mid-floor",
  },
  {
    label: "Pets allowed",
    value: "pets-allowed",
  },
  {
    label: "Private garage",
    value: "private-garage",
  },
  {
    label: "Garden",
    value: "garden",
  },

  {
    label: "Sauna",
    value: "sauna",
  },
  {
    label: "Shared swimming pool",
    value: "shared-swimming-pool",
  },
  {
    label: "Wood flooring",
    value: "wood-flooring",
  },
  {
    label: "Steam room",
    value: "steam-room",
  },
  {
    label: "Study",
    value: "study",
  },
  {
    label: "Upgraded interior",
    value: "upgraded-interior",
  },
  {
    label: "Garden view",
    value: "garden-view",
  },
  {
    label: "Sea/Water view",
    value: "sea-water-view",
  },

  {
    label: "Maintenance",
    value: "maintenance",
  },
  {
    label: "Within a Compound",
    value: "within-a-compound",
  },
  {
    label: "Indoor swimming pool",
    value: "indoor-swimming-pool",
  },
  {
    label: "Separate entrance for females",
    value: "separate-entrance-for-females",
  },
  {
    label: "Basement",
    value: "basement",
  },
  {
    label: "Storage room",
    value: "storage-room",
  },
  {
    label: "Community view",
    value: "community-view",
  },
  {
    label: "Golf view",
    value: "golf-view",
  },
  {
    label: "City view",
    value: "city-view",
  },
  {
    label: "North orientation",
    value: "north-orientation",
  },
  {
    label: "South orientation",
    value: "south-orientation",
  },
  {
    label: "East orientation",
    value: "east-orientation",
  },
  {
    label: "West orientation",
    value: "west-orientation",
  },
  {
    label: "Near school",
    value: "near-school",
  },
  {
    label: "Near hospital",
    value: "near-hospital",
  },
  {
    label: "Terrace",
    value: "terrace",
  },
  {
    label: "Near mosque",
    value: "near-mosque",
  },
  {
    label: "Near supermarket",
    value: "near-supermarket",
  },
  {
    label: "Near mall",
    value: "near-mall",
  },
  {
    label: "Near public transportation",
    value: "near-public-transportation",
  },
  {
    label: "Near metro",
    value: "near-metro",
  },
  {
    label: "Near veterinary",
    value: "near-veterinary",
  },
  {
    label: "Beach access",
    value: "beach-access",
  },
  {
    label: "Public parks",
    value: "public-parks",
  },
  {
    label: "Near restaurants",
    value: "near-restaurants",
  },
  {
    label: "Near Golf",
    value: "near-golf",
  },
  {
    label: "Near airport",
    value: "near-airport",
  },
  {
    label: "Concierge Service",
    value: "concierge-service",
  },
  {
    label: "Spa",
    value: "spa",
  },
  {
    label: "Shared Gym",
    value: "shared-gym",
  },
  {
    label: "Maid Service",
    value: "maid-service",
  },
  {
    label: "Walk-in Closet",
    value: "walk-in-closet",
  },
  {
    label: "Heating",
    value: "heating",
  },
  {
    label: "Ground floor",
    value: "ground-floor",
  },
  {
    label: "Server room",
    value: "server-room",
  },
  {
    label: "Pantry",
    value: "pantry",
  },
  {
    label: "Reception area",
    value: "reception-area",
  },
  {
    label: "Visitors parking",
    value: "visitors-parking",
  },
  {
    label: "Office partitions",
    value: "office-partitions",
  },
  {
    label: "Core and Shell",
    value: "core-and-shell",
  },
  {
    label: "Children daycare",
    value: "children-daycare",
  },
  {
    label: "Cleaning services",
    value: "cleaning-services",
  },
  {
    label: "Near Hotel",
    value: "near-hotel",
  },
  {
    label: "Conference room",
    value: "conference-room",
  },
]
