import { useState } from "react"

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search)
  }
  return new URLSearchParams()
}

const getQueryStringVal = (key) => {
  return getQuery().get(key)
}

const useQueryParam = (key, defaultVal) => {
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal)

  const updateUrl = (newVal) => {
    setQuery(newVal)

    const queryParams = getQuery()

    if (newVal.trim() !== "") {
      queryParams.set(key, newVal)
    } else {
      queryParams.delete(key)
    }

    // This check is necessary if using the hook with Gatsby
    if (typeof window !== "undefined") {
      const { protocol, pathname, host } = window.location
      const newUrl = `${protocol}//${host}${pathname}?${queryParams.toString()}`
      window.history.pushState({}, "", newUrl)
    }
  }

  return [query, updateUrl]
}

export default useQueryParam
